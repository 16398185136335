<template>
  <div class="card">
    <div class="card-body">
      <h4 class="card-title">Sent Messages</h4>
      <div class="row mt-4">
        <div class="col-md-3">
          <div class="form-group">
            <label>Search recipient</label>
            <input
              type="text"
              placeholder="Search recipient"
              class="form-control"
              v-model="filter.recipient"
            />
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label>Filter Status</label>
            <select class="form-control" v-model="filter.status">
              <option value="all">All</option>
              <option value="success">Success</option>
              <option value="failed">Failed</option>
            </select>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label>Date From</label>
            <date-picker input-class="form-control" placeholder="Date From" v-model="filter.date_from" format="dd-MM-yyyy" />
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label>Date To</label>
            <date-picker input-class="form-control" placeholder="Date To" v-model="filter.date_to" format="dd-MM-yyyy" />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <h4>Total: {{ pagination.total }}</h4>
            <a href="#" class="btn btn-primary btn-xs" @click.prevent="resendAll"
              ><i class="lni-comment mr-2"></i>Resend All</a
            >
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group text-right">
            <a href="#" class="btn btn-primary btn-xs" @click.prevent="fetch"
              ><i class="lni-search mr-2"></i>Apply</a
            >
          </div>
        </div>
      </div>
      <div class="row ">
        <div class="col-md-12">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Recipient</th>
                <th>Message</th>
                <th>Status</th>
                <th>Date</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(message, i) in messages" :key="`message-${i}`">
                <td>{{ message.phone_number }}</td>
                <td>{{ message.message }}</td>
                <td>
                  <span
                    :class="
                      `badge badge-${
                        message.status == 'success' ? 'success' : 'danger'
                      }`
                    "
                    >{{ message.status }}</span
                  >
                  <p class="mb-0" style="font-size: 14px">
                    {{ message.status == "success" ? "" : message.description }}
                  </p>
                </td>
                <td>{{ message.date }}</td>
                <td>
                  <a href="#" class="btn btn-primary btn-xs" @click.prevent="resend(message)">Resend</a>
                </td>
              </tr>
              <tr v-if="messages.length == 0">
                <td colspan="6">There is no data to display</td>
              </tr>
            </tbody>
          </table>
          <div v-if="messages.length > 0">
            <paginate
              :page-count="pagination.pages"
              :click-handler="fetch"
              v-model="pagination.current"
              :prev-text="'Prev'"
              :next-text="'Next'"
              :container-class="'pagination'"
              :page-class="'page-item'"
              page-link-class="page-link"
              prev-class="page-item"
              prev-link-class="page-link"
              next-class="page-item"
              next-link-class="page-link"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      messages: [],
      pagination: {
        current: 1,
        total: 1,
        pages: 1
      },
      filter: {
        recipient: '',
        status: 'all',
        date_from: '',
        date_to: ''
      }
    };
  },

  methods: {
    fetch() {
      this.$loader.start("main-content");

      this.$axios
        .get(`/api/v1/apps/${this.$route.params.id}/outbox?page=${this.pagination.current}&recipient=${this.filter.recipient}&status=${this.filter.status}&date_from=${this.filter.date_from != '' ? moment(this.filter.date_from).format('YYYY-MM-DD') : ''}&date_to=${this.filter.date_to != '' ? moment(this.filter.date_to).format('YYYY-MM-DD') : ''}`)
        .then((response) => {
          this.messages = response.data.messages;
          this.pagination = response.data.pagination
          this.$loader.stop();
        });
    },

    resend(message) {
      let data = {
        message_id: message.id
      }

      this.$loader.start()
      this.$axios.post(`/api/v1/apps/${this.$route.params.id}/resend`, data).then(() => {
        this.$loader.stop()
        this.fetch();
      })
    },

    resendAll() {
      this.$loader.start()
      this.$axios
        .post(`/api/v1/apps/${this.$route.params.id}/resend-all?page=${this.pagination.current}&recipient=${this.filter.recipient}&status=${this.filter.status}&date_from=${this.filter.date_from != '' ? moment(this.filter.date_from).format('YYYY-MM-DD') : ''}&date_to=${this.filter.date_to != '' ? moment(this.filter.date_to).format('YYYY-MM-DD') : ''}`)
        .then(() => {
          this.$loader.stop()
          this.fetch()
        })
    }
  },
};
</script>
